<template>
  <div class="table-filter-wrap">
    <div class="site-table-wrap products-table"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate([
              'payments_orderShipment',
              'payments_date',
              'payments_transactionAmount',
              'payments_userBalance',
              'payments_type',
              'payments_comment',
              'payments_transaction',
              'payments_moneyEarned',
              'payments_moneyWithdraw',
              'payments_complaintRequestId',
              ])"></span>

      <template v-if="$store.getters.getBalanceHistory.length > 0 && $store.getters.getBalanceHistoryLoading !== true">
        <table class="site-table mt-0" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('payments_orderShipment.localization_value.value')}} </th>
            <th>{{$t('payments_date.localization_value.value')}}</th>
            <th class="text-right">{{$t('payments_transactionAmount.localization_value.value')}},  $</th>
            <th class="text-right">{{$t('payments_userBalance.localization_value.value')}}</th>
            <th>{{$t('payments_type.localization_value.value')}}</th>
            <th>{{$t('payments_comment.localization_value.value')}}</th>
            <th>{{$t('payments_transaction.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getBalanceHistory" :key="index">
            <td>
              <div v-if="item.order_id">
                <template v-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).instance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id && !_.has(item.invoice.invoice_instance.instance, 'consolidation_destination_id')">
                  <router-link class="table-link btn-style"
                               :to="`${isUserHasEasyOrder ? $store.getters.GET_PATHS.easyOrderShow : $store.getters.GET_PATHS.expressOrderShow}/fedex/${item.order_id}`">
                    #{{item.order_id}}
                  </router-link>
                </template>
                <template v-else-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).instance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id && !_.has(item.invoice.invoice_instance.instance, 'consolidation_destination_id')">
                  <router-link class="table-link btn-style"
                               :to="`${$store.getters.GET_PATHS.expressOrderShow}/tnt/${item.order_id}`">
                    #{{item.order_id}}
                  </router-link>
                </template>
                <template v-else-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).instance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id && !_.has(item.invoice.invoice_instance.instance, 'consolidation_destination_id')">
                  <router-link class="table-link btn-style"
                               :to="`${$store.getters.GET_PATHS.expressOrderShow}/dhl/${item.order_id}`">
                    #{{item.order_id}}
                  </router-link>
                </template>
                <template v-else-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).instance.delivery_service_id === EXPRESS_DELIVERY_SERVICES.NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id">
                  <router-link class="table-link btn-style"
                               :to="`${$store.getters.GET_PATHS.expressOrderShow}/npg/${item.order_id}`">
                    #{{item.order_id}}
                  </router-link>
                </template>
                <template v-else-if="item.orderInstance && item.orderInstance.delivery_service_id === INTERNAL_DELIVERY_SERVICES.NOVAPOSHTA_DELIVERY_SERVICE.id && !_.has(item.invoice.invoice_instance.instance, 'consolidation_destination_id')">
                  <router-link class="table-link btn-style"
                               :to="`${$store.getters.GET_PATHS.novaPoshta}/show/${item.order_id}`">
                    #{{item.order_id}}
                  </router-link>
                </template>
                <template v-else-if="item.orderInstance && item.orderInstance.delivery_service_id === INTERNAL_DELIVERY_SERVICES.CDEK_DELIVERY_SERVICE.id && !_.has(item.invoice.invoice_instance.instance, 'consolidation_destination_id')">
                  <router-link class="table-link btn-style"
                               :to="`${$store.getters.GET_PATHS.cdek}/show/${item.order_id}`">
                    #{{item.order_id}}
                  </router-link>
                </template>
                <template v-else-if="hasInvoiceInstance(item) && _.has(item.invoice.invoice_instance.instance, 'amazon_sets_cost') && item.invoice.invoice_instance.instance.amazon_sets_cost">
                  <router-link class="table-link btn-style"
                               v-if="orderFbaId = item.invoice.invoice_instance.instance.id"
                               :to="`${$store.getters.GET_PATHS.ordersFBA}/show/${orderFbaId}`">
                    #{{item.order_id}}
                  </router-link>
                </template>
                <template v-else-if="hasInvoiceInstance(item) && hasInvoiceInstance(item).instance && hasInvoiceInstance(item).instance && item.invoice.invoice_instance.model === 'order_fbm'">
                  <router-link class="table-link btn-style"
                               v-if="(orderFbmId = item.invoice.invoice_instance.instance.id) && (order_type_id = item.invoice.invoice_instance.instance.order_type_id)"
                               :to="`${$store.getters.GET_PATHS.ordersFBM}/show/${getOrderFBMTypeById(order_type_id).name}/${orderFbmId}`">
                    #{{item.order_id}}
                  </router-link>
                </template>
                <template v-else-if="hasInvoiceInstance(item) && _.has(item.invoice.invoice_instance.instance, 'label_presence') && item.invoice.invoice_instance.instance.label_presence">
                  <router-link class="table-link btn-style"
                               v-if="orderInbound = item.invoice.invoice_instance.instance"
                               :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                                getOrderType(orderInbound['fulfillment'], orderInbound['return']).value + '/' + orderInbound.id">
                    #{{item.order_id}}
                  </router-link>
                </template>
                <template v-else-if="hasInvoiceInstance(item) && _.has(item.invoice.invoice_instance.instance, 'consolidation_destination_id') && item.invoice.invoice_instance.instance.consolidation_destination_id">
                  <router-link class="table-link btn-style"
                               v-if="consolidationId = item.invoice.invoice_instance.instance.id"
                               :to="$store.getters.GET_PATHS.ordersConsolidationDetail + '/' +
                                getConsolidationTypeByDestinationId(item.invoice.invoice_instance.instance.consolidation_destination.id) + '/' + consolidationId">
                    #{{item.order_id}}
                  </router-link>
                </template>
                <template v-else-if="item.invoice && item.invoice.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_DISPOSAL.id">
                  <router-link class="table-link btn-style"
                               :to="$store.getters.GET_PATHS.warehouseDisposalOrders + '?id=' + item.order_id">
                    #{{item.order_id}}
                  </router-link>
                </template>
                <template v-else-if="item.invoice && item.invoice.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_MEEST.id">
                  <router-link class="table-link btn-style"
                               :to="$store.getters.GET_PATHS.ordersMeestReturns + '/show/' + item.order_id">
                    #{{item.order_id}}
                  </router-link>
                </template>

                <div v-else style="font-size: 20px">
                  &mdash;
                </div>
              </div>


              <div v-else-if="!item.order_id && item.payed_invoice && item.payed_invoice.invoice_instance">
                <template v-if="item.payed_invoice && _.has(item.payed_invoice.invoice_instance.instance, 'etsy_listing_id')">
                  <router-link class="table-link btn-style"
                               :to="`${$store.getters.GET_PATHS.financeUkrTreasuresLink}?id=${item.payed_invoice.invoice_instance.instance.id}`">
                    #{{item.payed_invoice.invoice_instance.instance.id}}
                  </router-link>
                </template>
                <template v-else>#{{item.payed_invoice.invoice_instance.instance.id}}</template>
              </div>

              <span v-else style="font-size: 20px">
                &mdash;
              </span>
            </td>
            <td style="text-transform: capitalize;">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td align="right">
              <template v-if="item.payment_type === 'remove_money'">-</template>
              $ {{item.amount | number('0.00')}}
            </td>
            <td align="right">
              $ {{item.user_balance}}
            </td>
            <td>
              <span v-if="item.payment_type === 'add_money'">
                {{$t('payments_moneyEarned.localization_value.value')}}
              </span>
              <span v-if="item.payment_type === 'remove_money'">
                {{$t('payments_moneyWithdraw.localization_value.value')}}
              </span>
            </td>


            <td>
<!--              {{consoleValue(item)}}-->
              <template v-if="fromInvoice(item) || fromOutput(item)">
                <BalanceHistoryTableComment
                        :item="item"
                        :orderInstance="item.orderInstance"
                        :payedInvoice="item.payed_invoice"
                />
              </template>
              <template v-else-if="_.has(item.payment_transaction, 'payment_system') &&
                item.payment_transaction.payment_system === CUSTOMERS_PAYMENTS_STATUSES.SYSTEM_TRANSACTION_STATUS">
                <div class="table-row payment-comment">
                  <div v-if="item.comment.indexOf('SkladUSA Fee - Id') > -1"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit"  @click="editTranslate(['payments_SkladUSAFee',])"></div>
                    {{$t('payments_SkladUSAFee.localization_value.value')}}
                  </div>
                  <TooltipTextHelper v-else>
                    <template slot="text">
                      <ValueHelper
                          :value="item"
                          :deep="'payment_transaction.payment_transaction_detail.description'"
                      />
                    </template>
                    <template slot="paragraph">
                      <ValueHelper
                          :value="item"
                          :deep="'payment_transaction.payment_transaction_detail.description'"
                      />
                    </template>
                  </TooltipTextHelper>
                </div>
              </template>
              <template v-else-if="fromPayPalCase(item)">
                {{$t('payments_complaintRequestId.localization_value.value')}}:
                <router-link class="table-link btn-style"
                             :to="`${$store.getters.GET_PATHS.financePayPalCases}?page=1&count=25&complaintId=${item.order_id}`">
                  <ValueHelper
                      :value="item"
                      :deep="'order_id'"
                  />
                </router-link>
              </template>
              <template v-else>
                {{item.comment}}
              </template>
            </td>


            <td>
              <template v-if="_.has(item.payment_transaction, 'payment_system') &&
                item.payment_transaction.payment_system === CUSTOMERS_PAYMENTS_STATUSES.SYSTEM_TRANSACTION_STATUS">
                <router-link
                        class="site-link"
                        :to="$store.getters.GET_PATHS.cusPayments + '?type=other&order=' + item.payment_transaction_id">
                  #{{item.payment_transaction_id}}
                </router-link>
              </template>

              <template v-else-if="_.has(item.payment_transaction, 'payment_system') &&
                item.payment_transaction.payment_system === PAYMENT_TYPE_NAME.authorize">
                <router-link
                        class="site-link"
                        :to="getRouteBySluiceType(item.payment_transaction) + `?type=${PAYMENT_TYPE_NAME.authorize}&order=` + item.payment_transaction_id">
                  #{{item.payment_transaction_id}}
                </router-link>
              </template>
              <template v-else-if="_.has(item.payment_transaction, 'payment_system') &&
                item.payment_transaction.payment_system === PAYMENT_TYPE_NAME.payPall">
                <router-link
                        class="site-link"
                        :to="getRouteBySluiceType(item.payment_transaction) + `?type=${PAYMENT_TYPE_NAME.payPall}&order=` + item.payment_transaction_id">
                  #{{item.payment_transaction_id}}
                </router-link>
              </template>
              <template v-else-if="_.has(item.payment_transaction, 'payment_system') &&
                item.payment_transaction.payment_system === PAYMENT_TYPE_NAME.braintree">
                <router-link
                        class="site-link"
                        :to="getRouteBySluiceType(item.payment_transaction) + `?type=${PAYMENT_TYPE_NAME.braintree}&order=` + item.payment_transaction_id">
                  #{{item.payment_transaction_id}}
                </router-link>
              </template>
              <!--   If transaction from other user  -->
<!--              <template v-else-if="item.comment.indexOf('Funds Requests id') === 0 && item.orderInstance.recipient_payment_transaction">-->
<!--                <router-link class="site-link"-->
<!--                             :to="`${$store.getters.GET_PATHS.cusPayments}?page=1&count=25&type=other&order=${item.orderInstance.recipient_payment_transaction.id}`">-->
<!--                  {{ item.orderInstance.recipient_payment_transaction.id }}-->
<!--                </router-link>-->
<!--              </template>-->

              <template v-else>
                <ValueHelper
                        v-if="item.payment_transaction"
                        :value="item.payment_transaction.transaction_id"/>
                <template v-else>&mdash;</template>
              </template>

            </td>
          </tr>


          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list pt-0">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getBalanceHistory"
                 :key="index"
            >
              <BalanceHistoryTableMobile
                :item="item"
              />
            </div>
          </div>
        </div>

      </template>
      <template v-if="$store.getters.getBalanceHistoryLoading === false && $store.getters.getBalanceHistory.length === 0">
        <NoResult
                :countFilterParams="countFilterParams"
                :title="$t('common_noDataYet.localization_value.value')"
                :titleWithFilter="$t('common_noResult.localization_value.value')"
                :text="$t('common_noDataYetTxt.localization_value.value')"
                :textWithFilter="$t('common_noResultSeems.localization_value.value')"
                @resetFilter="$emit('resetFilter')"
        />
      </template>


      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore class=""
                    v-if="$store.getters.getBalanceHistoryCommonList.next_page_url !== null && $store.getters.getBalanceHistory.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextBalanceHistoryPage}"
                    :count="$store.getters.getBalanceHistoryCommonList.total - $store.getters.getBalanceHistoryForPage * $store.getters.getBalanceHistoryCommonList.current_page < $store.getters.getBalanceHistoryForPage ?
                  $store.getters.getBalanceHistoryCommonList.total - $store.getters.getBalanceHistoryForPage * $store.getters.getBalanceHistoryCommonList.current_page:
                  $store.getters.getBalanceHistoryForPage"
          />

          <ExportBtn
                  class="table-bottom-btn__right"
                  @downloadFiles="type => $emit('downloadFiles', type)"
          />

        </div>
      </div>
    </div>



  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import BalanceHistoryTableMobile from "./BalanceHistoryTableMobile/BalanceHistoryTableMobile";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {
    CUSTOMERS_PAYMENTS_STATUSES,
    EXPRESS_DELIVERY_SERVICES,
    INTERNAL_DELIVERY_SERVICES, INVOICES_TYPES, PAYMENT_TYPE_NAME, TRANSACTION_SLUICE_TYPES
  } from "../../../../../../../staticData/staticVariables";
  import BalanceHistoryTableComment from "./BalanceHistoryTableComment/BalanceHistoryTableComment";
  import {ordersInboundTableMixin} from "../../../../../../../mixins/ordersInbound/ordersInboundMixin";
  import {FBMMixinsHelper} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import {consolidationMixin} from "../../../../../../../mixins/consolidationMixins/consolidationMixin";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";

  export default {
    name: "BalanceHistoryTable",
    components: {
      TooltipTextHelper,
      BalanceHistoryTableComment,
      ValueHelper,
      ShowMore,
      ExportBtn,
      NoResult,
      BalanceHistoryTableMobile,
    },

    mixins: [mixinDetictingMobile, ordersInboundTableMixin, FBMMixinsHelper, consolidationMixin],

    data(){
      return{
        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        EXPRESS_DELIVERY_SERVICES: EXPRESS_DELIVERY_SERVICES,
        INTERNAL_DELIVERY_SERVICES: INTERNAL_DELIVERY_SERVICES,
        PAYMENT_TYPE_NAME: PAYMENT_TYPE_NAME,
        INVOICES_TYPES: INVOICES_TYPES,
      }
    },

    methods: {

      hasInvoiceInstance(item){
        return item?.invoice?.invoice_instance
      },

      fromPayPalCase(item) {
        return item.orderInstance && item.orderInstance?.complaint_type_id
      },

      fromInvoice(item) {
        return item.invoice
      },

      fromOutput(item) {
        return item.orderInstance && item.orderInstance.balance_before_output
      },

      getRouteBySluiceType(item) {
        if(item.transaction_sluice === TRANSACTION_SLUICE_TYPES.INTERNAL)
          return this.$store.getters.GET_PATHS.financeMyPayments

        return this.$store.getters.GET_PATHS.cusPayments
      },

      consoleValue(val) {
        console.log(val.comment, val);
      }
    },

  }
</script>

<style scoped>
  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    /*padding-left: 8px;*/
    /*padding-right: 0;*/
  }

  .site-table thead th:nth-child(2),
  .site-table tbody td:nth-child(2){
    /*padding-left: 1px;*/
  }


  .comment-row{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 290px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  .payment-comment{
    width: 300px;
  }
</style>
